import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from "react-toastify";
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
//custom imports
import styles from '../../../assets/stylesheets/pages/project/project.module.scss'
import Table from '../../../uicomponents/table/Table'
import { getProjectsForAudit, getAuditCallersList, getQmfscorerulebook } from './service'
import useApi from '../../../hooks/useApi'
import Heading from '../../../uicomponents/heading'
import handleErrorMessage from '../../../helper/handleErrorMessage'
import FutworkButton from '../../../uicomponents/button'
import Popup from '../../../uicomponents/popup'
import DownloadReport from '../../project/main/downloadReport'
import jwt_decode from "jwt-decode"
import constants from '../../../util/constants'

const roles = localStorage.authToken ? [jwt_decode(localStorage.authToken).auth] : [];
const isTeamLead = () => {
    return roles && roles.includes(constants.customRole.TEAM_LEAD) ? true : false
}

const MainQmf = (props) => {
    const {state: {page, query}} = props.location
    const searchBy = query
    const getAuditCallersListApi = useApi(getAuditCallersList)

    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sort, setSort] = useState([])
    const [search, setSearch] = useState(searchBy ? searchBy : '')
    const [currentPage, setCurrentPage] = useState(page ? page : 1);
    const getQmfscorerulebookApi = useApi(getQmfscorerulebook)
    const [reportModal,setReportModal] = useState({
        type: '',
        isOpen: false
    })

    useEffect(() => {
        addParamToUrl()
        const skip = (currentPage - 1) * limit
        getAuditCallersListApi.request(skip, limit, sort, search)
    }, [currentPage, sort, search])

    const isInteger = (value = '') => {
        return /^-?\d+$/.test(value)
    }

    const addParamToUrl = () => {
        if(search){
            props.history.push({
                pathname: `${props.location.pathname}`,
                state: {
                    activeTab: props.location.state.activeTab,
                    page: page,
                    query: search
                }
            })
        }else{
            props.history.push({
                pathname: `${props.location.pathname}`,
                state: {
                    activeTab: props.location.state.activeTab,
                    page: page,
                    query: search
                }
            })
        }
    }

    const disabledRow = (children) => {
        return <div className={styles['disabled-row']}>{children}</div>
    }

    const columns = [
        {
            Header: 'S.No',
            width: "1%",
            Cell: (cell) => {
                let rowcomponent = <span>{((currentPage - 1) * limit) + (Number(cell.row.id) + 1)}</span>
                return cell.row.original.status !== 'inprogress' ? disabledRow(rowcomponent) : rowcomponent
            },
            disableSortBy: true
        },
        {
            Header: 'Caller name',
            accessor: 'name',
            Cell: ({ cell }) => {
                let rowcomponent = <span>{cell.row.original.name}</span>
                return cell.row.original.status !== 'inprogress' ? disabledRow(rowcomponent) : rowcomponent
            },
            width: "10%",
            disableSortBy: true
        },
        {
            Header: 'Mobile no',
            accessor: 'mobile',
            Cell: ({ cell }) => {
                let rowcomponent = <span>{cell.row.original.mobile}</span>
                return cell.row.original.status !== 'inprogress' ? disabledRow(rowcomponent) : rowcomponent
            },
            width: "8%",
            disableSortBy: true
        },
        // {
        //     Header: 'Counts of calls',
        //     accessor: 'callsCount',
        //     Cell: ({ cell }) => {
        //         let rowcomponent = <span>{cell.row.original.callsCount}</span>
        //         return cell.row.original.status !== 'inprogress' ? disabledRow(rowcomponent) : rowcomponent
        //     },
        //     width: "9%",
        //     disableSortBy: true
        // },
        {
            Header: 'Audit count',
            accessor: 'auditCount',
            Cell: (cell) => {
                let rowcomponent = <span>{cell.row.original.auditCount}</span>
                return cell.row.original.status !== 'inprogress' ? disabledRow(rowcomponent) : rowcomponent
            },
            width: "9%"
        },
        {
            Header: 'Pending audit',
            accessor: 'pendingCount',
            Cell: (cell) => {
                let rowcomponent = <span>{cell.row.original.pendingCount}</span>
                return cell.row.original.status !== 'inprogress' ? disabledRow(rowcomponent) : rowcomponent
            },
            width: "9%"
        },
        {
            Header: 'Audit score',
            accessor: 'auditScore',
            Cell: (cell) => {
                let rowcomponent = <span>{cell.row.original.auditScore}</span>
                return cell.row.original.status !== 'inprogress' ? disabledRow(rowcomponent) : rowcomponent
            },
            width: "9%"
        },
        {
            Header: 'Fatal count',
            accessor: 'fatalCount',
            Cell: (cell) => {
                let rowcomponent = <span>{cell.row.original.fatalCount}</span>
                return cell.row.original.status !== 'inprogress' ? disabledRow(rowcomponent) : rowcomponent
            },
            width: "9%"
        },
        {
            Header: 'Last retraining date',
            Cell: ({ cell }) => {
                let rowcomponent = <span>{cell.row.original.retrainingDate !== 'NA' ? moment(cell.row.original.retrainingDate).format("DD/MM/YYYY") : "NA"}</span>
                return cell.row.original.status !== 'inprogress' ? disabledRow(rowcomponent) : rowcomponent
            },
            width: "9%",
            disableSortBy: true
        },
        {
            Header: 'Project',
            accessor: 'title',
            Cell: (cell) => {
                let rowcomponent = <span>{cell.row.original.title}</span>
                return cell.row.original.status !== 'inprogress' ? disabledRow(rowcomponent) : rowcomponent
            },
            width: "15%",
            disableSortBy: true
        },
        {
            Header: '',
            accessor: "action",
            Cell: (cell) => {
                let rowcomponent = <div className={[styles['arrow']].join('')}
                    onClick={() => {
                        if (cell.row.original.status == 'inprogress')
                            props.history.push({
                                pathname: `${props.location.pathname}/qmf/calls-list/${cell.row.original.userId}`,
                                state: {
                                    callerDetails: cell.row.original,
                                    activeTab: props.location.state.activeTab,
                                    page: currentPage,
                                    query: search
                                }
                            })
                    }
                    }>
                    <FontAwesomeIcon icon={faCaretRight} />
                </div>
                return cell.row.original.status !== 'inprogress' ? disabledRow(rowcomponent) : rowcomponent
            },
            width: "3%",
            disableSortBy: true
        }
    ]

    const filterChange = (searchInput) => {
        setSearch(searchInput.trim())
        setCurrentPage(1)
    }

    const sortChange = (sortArray) => {
        if (sortArray !== '')
            setSort(sortArray)
        if (sortArray === '' && sort.length == 1)
            setSort([])
    }

    const renderDownloadReport = () => {
        let category = []
        if(res && res.length>0){
            category = [...new Set(res.map(item => item.category))]
        }
        return(
            <Popup
            show={reportModal.isOpen}
            closePopup={()=>{
                setReportModal({type: '', isOpen: false})
            }}
            size='sm'
            heading={reportModal.type}
             >
            <DownloadReport data={{reportType: 'Performance Audit Report'}} type={true} qmfCategory={category}/>
            </Popup>
        )
    }

    const handlePopups = () => {
        if (reportModal.isOpen) return renderDownloadReport()
    }

    let { data, total } = getAuditCallersListApi.data || {}
    const { loading, error } = getAuditCallersListApi

    useEffect(() => {
        if (error)
            toast(handleErrorMessage(error))
    }, [error])
    const { data:res, loading:resLoading, error:resError } = getQmfscorerulebookApi


    return (
        <div 
        // className={styles.page_wrapper}
        >
            <div className={styles.main_qmf_row}>
                {/* <Heading text={'QMF (New)'} /> */}
                {isTeamLead() ? null : <FutworkButton buttonSize='sm' buttonStyle='primary--solid non-rounded'
                 onClick={async () => {
                    await getQmfscorerulebookApi.request()
                    setReportModal({type: 'QMF Audit Report', isOpen: true})
                }}
                >SEND REPORT</FutworkButton>}
            </div>
            <div>
                <Table
                    columns={columns}
                    data={data}
                    totalCount={total}
                    limit={limit}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    loading={loading}
                    sortChange={sortChange}
                    filterChange={filterChange}
                    searchBy='project title or mobile'
                    isGlobalFilter={true}
                    search={search}
                />
            </div>
            {handlePopups()}
            <ToastContainer draggable={true} />
        </div>
    )
}

export default MainQmf