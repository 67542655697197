import ApiActions from "../../actions/ApiActions";
import generatePayload from "../../helper/generatePayloadForTable";

const getTLTeamsListForAdmin = async(skip = 0, limit = 10, sort = null, search = false, dynamicSearch = false) => {
    let payload = generatePayload(skip, limit, sort, search, dynamicSearch)
    return await ApiActions.getTLTeamsListForAdmin(payload)
}

const fetchAgentsByTeamLead = async(skip = 0, limit = 10, sort = null, search = false, dynamicSearch = true, extradata) => {
    let payload = generatePayload(skip, limit, sort, search, dynamicSearch)
    payload = { ...payload, ...extradata }
    return await ApiActions.fetchAgentsByTeamLead(payload)
}


const viewAgentsProjects = async(data = {}) => {
    return await ApiActions.getCallerCurrentJobs(data)
}

const addAgentToTL = async(data={}) => {
    return await ApiActions.addAgentToTL(data)
}

const removeAgentAssignedtoTL = async(data={}) => {
    return await ApiActions.removeAgentAssignedtoTL(data)
}

const getAgentDetails = async(mobilenum) => {
    return await ApiActions.getAgentDetails(mobilenum)
}

export {
    getTLTeamsListForAdmin,
    fetchAgentsByTeamLead,
    viewAgentsProjects,
    addAgentToTL,
    removeAgentAssignedtoTL,
    getAgentDetails
}