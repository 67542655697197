import ApiActions from "../../../actions/ApiActions";
import generatePayload from '../../../helper/generatePayloadForTable'

const getIqcCallersList = async(skip, limit, sort, search) => {
    let payload = generatePayload(skip, limit, sort, search)
    return await ApiActions.getIqcCallersList(payload)
}

const getIqcFlaggedCalls = async(skip, limit, sort, search, data) => {
    let payload = generatePayload(skip, limit, sort, search)
    payload = { ...payload, ...data }
    return await ApiActions.getIqcFlaggedCallsV2(payload)
}

const getQmfscorerulebook = async (qmfCategory) => {
    return await ApiActions.getQmfscorerulebook(qmfCategory)
}

const getTeletaskCallHistory = async (payload) => {
    return await ApiActions.getTeletaskCallHistory(payload)
}

const getTeleProject = async (teleprojectId) => {
    return await ApiActions.getTeleProject(teleprojectId)
}

const takeIqcAction = async(data) => {
    return await ApiActions.takeIqcAction(data)
}

const updateqmfscore = async(data) => {
    return await ApiActions.updateqmfscore(data)
}
export{
    getIqcCallersList,
    getIqcFlaggedCalls,
    getQmfscorerulebook,
    getTeletaskCallHistory,
    getTeleProject,
    takeIqcAction,
    updateqmfscore
}