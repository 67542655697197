import React, {useState, useContext} from "react";
import FutworkButton from "../../uicomponents/button";
import { removeAgentAssignedtoTL } from "./service"
import { TeamManagementContext } from "./teammanagementContext"
import styles from "../../assets/stylesheets/pages/teammanagement/teamlead.module.scss"

const RemoveAgent = (props) => {
    const { teamSelected } = useContext(TeamManagementContext)
    const [apiResponse, setApiResponse] = useState({
        status: '',
        msg: '',
        loading: false
    })
    const removeAgentFomTeam = async () => {
        
        try {
            const {id: teamLeadId} = teamSelected
            const payload = {
                agents: props.agents,
                teamLeadId
            }

            const result = await removeAgentAssignedtoTL(payload)
    
            if(result && result.data){
                setApiResponse({
                    loading: false,
                    msg: result.data.msg,
                    status: 200
                })
            }

        } catch (error) {
            const {response: {data}} = error
            setApiResponse({
                status: 400,
                msg: data && data.error ? data.error : `Something went wrong`,
                loading: false
            })
        }
    }
    return (
        <div>
            {apiResponse.msg ?
             apiResponse.status == 200 ? <div className={`${styles['success-msg']}`}>{apiResponse.msg}</div> :  apiResponse.status == 400 ? <div className={`${styles['error-msg']}`}>{apiResponse.msg}</div> : null
            : 
            <>
            <div className={styles.remove_agent_text}>Are you sure you want to remove the agent ? </div>
            <div className={styles.action_footer}>
                <FutworkButton buttonStyle={'success--outline'} buttonSize={'medium'} onClick={() => removeAgentFomTeam()}>Yes</FutworkButton>
                <FutworkButton buttonStyle={'danger--outline'} buttonSize={'medium'} onClick={props.close}>No</FutworkButton>
            </div>
            </>}
        </div>
    )
}

export default RemoveAgent