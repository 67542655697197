import React, { useState, useEffect } from 'react'
import { ToastContainer, toast } from "react-toastify";
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
//custom imports
import styles from '../../../assets/stylesheets/pages/project/project.module.scss'
import useApi from '../../../hooks/useApi'
import Table from '../../../uicomponents/table/Table'
import FutworkButton from '../../../uicomponents/button'
import Popup from '../../../uicomponents/popup'
import { getIqcCallersList, getQmfscorerulebook } from './service'
import handleErrorMessage from '../../../helper/handleErrorMessage'
import DownloadReport from '../../project/main/downloadReport'

const MainIqc = (props) => {
    const {state: {page, query}} = props.location
    const searchBy = query
    const getIqcCallersListApi = useApi(getIqcCallersList)
    const getQmfscorerulebookApi = useApi(getQmfscorerulebook)


    const [limit, setLimit] = useState(10)
    const [sort, setSort] = useState([])
    const [search, setSearch] = useState(searchBy ? searchBy : '')
    const [currentPage, setCurrentPage] = useState(page ? page : 1);
    const [reportModal,setReportModal] = useState({
        type: '',
        isOpen: false
    })

    useEffect(() => {
        addParamToUrl()
        const skip = (currentPage - 1) * limit
        getIqcCallersListApi.request(skip, limit, sort, search)
    }, [currentPage, sort, search])

    const addParamToUrl = () => {
        if(search){
            props.history.push({
                pathname: `${props.location.pathname}`,
                state: {
                    activeTab: props.location.state.activeTab,
                    page: page,
                    query: search
                }
            })
        }else{
            props.history.push({
                pathname: `${props.location.pathname}`,
                state: {
                    activeTab: props.location.state.activeTab,
                    page: page
                }
            })
        }
    }

    const disabledRow = (children) => {
        return <div className={styles['disabled-row']}>{children}</div>
    }

    const columns = [
        {
            Header: 'S.No',
            width: "1%",
            Cell: (cell) => {
                return <span>{((currentPage - 1) * limit) + (Number(cell.row.id) + 1)}</span>
            },
            disableSortBy: true
        },
        {
            Header: 'Caller name',
            accessor: 'name',
            Cell: ({value}) => (value || 'NA'),
            width: "10%",
            disableSortBy: true
        },
        {
            Header: 'Mobile no',
            accessor: 'mobile',
            Cell: ({value}) => (value || 'NA'),
            width: "8%",
            disableSortBy: true
        },
        {
            Header: 'Project',
            accessor: 'title',
            Cell: ({value}) => (value || 'NA'),
            width: "15%",
            disableSortBy: true
        },
        {
            Header: 'Eligible count',
            accessor: 'eligibleCount',
            Cell: ({value}) => (value || 0),
            width: "9%"
        },
        {
            Header: 'Audit completed',
            accessor: 'auditedCount',
            Cell: ({value}) => (value || 0),
            width: "9%"
        },
        {
            Header: 'IQC due since',
            accessor: 'iqcDueSince',
            Cell: ({ cell }) => {
                return <span>{cell.row.original && cell.row.original.iqcDueSince &&  
                (cell.row.original.iqcDueSince !== '' || cell.row.original.iqcDueSince !== 'NA') 
                ? moment(cell.row.original.iqcDueSince).format("DD/MM/YYYY") : 'NA'}</span>
            },
            width: "9%",
            disableSortBy: true
        },
        
        
        {
            Header: '',
            accessor: "action",
            Cell: (cell) => {
                let rowcomponent = <div className={[styles['arrow']].join('')}
                    onClick={() => {
                    //     if (cell.row.original.status == 'inprogress')
                            props.history.push({
                                pathname: `${props.location.pathname}/iqc/calls-list/${cell.row.original.id}`,
                                state: {
                                    callerDetails: cell.row.original,
                                    activeTab: props.location.state.activeTab,
                                    page: currentPage,
                                    query: search
                                }
                            })
                    }
                    }
                    >
                    <FontAwesomeIcon icon={faCaretRight} />
                </div>
                // return cell.row.original.status !== 'inprogress' ? disabledRow(rowcomponent) : rowcomponent
                return rowcomponent
            },
            width: "3%",
            disableSortBy: true
        }
    ]

    const filterChange = (searchInput) => {
        setSearch(searchInput.trim())
        setCurrentPage(1)
    }

    const sortChange = (sortArray) => {
        if (sortArray !== '')
            setSort(sortArray)
        if (sortArray === '' && sort.length == 1)
            setSort([])
    }

    const renderDownloadReport = () => {
        let category = []
        if(res && res.length>0){
            category = [...new Set(res.map(item => item.category))]
        }
        return(
            <Popup
            show={reportModal.isOpen}
            closePopup={()=>{
                setReportModal({type: '', isOpen: false})
            }}
            size='sm'
            heading={reportModal.type}
             >
            <DownloadReport data={{reportType: 'IQC Audit Report'}} type={true} qmfCategory={category} />
            </Popup>
        )
    }


    const handlePopups = () => {
        if (reportModal.isOpen) return renderDownloadReport()
    }

    let { data, total } = getIqcCallersListApi.data || {}
    const { loading, error } = getIqcCallersListApi
    
    useEffect(() => {
        if (error)
            toast(handleErrorMessage(error))
    }, [error])
    const { data:res, loading:resLoading, error:resError } = getQmfscorerulebookApi
    return(
        <div
        // className={styles.page_wrapper}
        >
            <div className={styles.main_qmf_row}>
                {/* <Heading text={'QMF (New)'} /> */}
                <FutworkButton buttonSize='sm' buttonStyle='primary--solid non-rounded'
                onClick={async () => {
                    await getQmfscorerulebookApi.request()
                    setReportModal({type: 'IQC Audit Report', isOpen: true})
                }}
                >SEND REPORT</FutworkButton>
            </div>
            <div>
                <Table
                columns={columns}
                data={data}
                totalCount={total}
                limit={limit}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                loading={loading}
                sortChange={sortChange}
                filterChange={filterChange}
                searchBy='project title or mobile'
                isGlobalFilter={true}
                search={search}
                />
            </div>
            {handlePopups()}
            <ToastContainer draggable={true} />
        </div>
    )
}

export default MainIqc