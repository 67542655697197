import React, {useState, createContext, useContext} from 'react'
import jwt_decode from "jwt-decode";

export const TeamManagementContext = createContext()

export const TeamManagementProvider = (props) => {
    const [isTeamPresent, setisTeamPresent] = useState(true)
    const [agentListView, setAgentListView] = useState(false)
    const loggedInUserRole = localStorage && localStorage.authToken && jwt_decode(localStorage.authToken).auth
    const loggedInUserName = localStorage.userName
    const [teamSelected, setTeamSelected] = useState('')

    const providerValue = {
        agentListView, 
        setAgentListView,
        isTeamPresent, 
        setisTeamPresent,
        loggedInUserRole,
        loggedInUserName,
        teamSelected, setTeamSelected
    }

    return(
        <TeamManagementContext.Provider value={providerValue}>
            {props.children}
        </TeamManagementContext.Provider>
    )
}