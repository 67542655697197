import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch, matchPath } from "react-router-dom";
import TeamManagement from '../pages/tlteammanagement/main'
import { TeamManagementProvider } from "../pages/tlteammanagement/teammanagementContext"

const TLTeamRouter = (props) => {
    const { path } = props.match
    const customProps = {
        ...props,
        modulePath: path
    }

    return <Switch>
        <TeamManagementProvider>
            <Route exact path={`${path}`}
            render={() => <TeamManagement {...customProps} />}
            />
        </TeamManagementProvider>

        {/* if there is no route match then redirect to the root path */}
        <Route render={() => <Redirect to="/" />} />
    </Switch>
}

export default TLTeamRouter