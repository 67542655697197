import React, {useState, useEffect, useContext} from 'react'
import Table from '../../uicomponents/table/Table'
import { BackArrow, TrashIcon, TeammanagementFallback} from "../../assets/images/index"
import { TeamManagementContext } from "./teammanagementContext"
import styles from "../../assets/stylesheets/pages/teammanagement/teamlead.module.scss"
import { fetchAgentsByTeamLead } from "./service"
import useApi from '../../hooks/useApi'
import FutworkButton from '../../uicomponents/button'
import Heading from '../../uicomponents/heading'
import Popup from '../../uicomponents/popup'
import ProjectList from './projectList'
import AddAgent from './addagent'
import RemoveAgent from './removeagent'
import capitalise from '../../helper/Capitalise'

const AgentList = (props) => {
    const { setAgentListView, teamSelected, setTeamSelected } = useContext(TeamManagementContext)

    const [currentPage, setCurrentPage] = useState(1)
    const [showProject, setShowProject] = useState(false)
    const [showAddAgent, setShowAddAgent] = useState(false)
    const [showRemoveAgent, setShowRemoveAgent] = useState(false)
    const [limit, setLimit] = useState(10)
    const [search, setSearch] = useState('')
    const [markedAgent, setMarkedAgent] = useState([])
    const fetchAgentsByTeamLeadApi = useApi(fetchAgentsByTeamLead)

    const [isFilterOff, setIsFilterOff] = useState(true)

    useEffect(() => {
        fetchData()
    },[search, currentPage])

    const fetchData = () => {
        const skip = (currentPage - 1) * limit
        const {id: teamLeadId} = teamSelected || {}
        // {teamLeadId: "6415b06f5fd9fd33d8cfc4a6"}
        if(search == '') setIsFilterOff(true)
        fetchAgentsByTeamLeadApi.request(skip,limit,null,search,true,{teamLeadId})
    }

    const filterChange = (searchInput) => {
        setIsFilterOff(false)
        setSearch(searchInput)
        setCurrentPage(1)
    }

    const renderProjectView = () => {
        const {agent:{name}} = teamSelected || {}
        return <Popup
        show={showProject}
        closePopup={() => {
            const {agent, ...rest} = teamSelected
            setTeamSelected(rest)
            setShowProject(false)
        }}
        size='md'
        heading={`Projects of Agent : ${name}`}
        >
            <ProjectList />
        </Popup>
    }

    const renderAddAgentTool = () => {
        const {teamName} = teamSelected

        return <Popup
        show={showAddAgent}
        closePopup={() => {
            setShowAddAgent(false)
            fetchData()
        }}
        size='md'
        heading={`Add agent's in ${teamName}`}
        >
            <AddAgent />
        </Popup>
    }

    const renderRemoveAgentTool = () => {
        const {teamName} = teamSelected

        const closeTool = () => {
            setShowRemoveAgent(false)
            fetchData()
        }

        return <Popup
        show={showRemoveAgent}
        closePopup={() => {
            closeTool()
        }}
        size='sm'
        heading={`Remove agent's from ${teamName}`}
        >
            <RemoveAgent close={() => closeTool()} agents={markedAgent}/>
        </Popup>
    }

    const formatCallerStatus = (status) => {
        switch (status) {
            case 'looking_to_work':
                return `Looking for a job`
            case 'working':
                return `Working`
            case 'ban':
                return `Ban`
            case 'not_looking_to_work':
                return `Not looking to work`
            case 'on_a_break':
                return `On a break`
            default:
                return status ? capitalise(status.replace(/_/g, " ")) : 'NA'
        }
    }

    const styleStatus = (status) => {
        switch (status) {
            case 'looking_to_work':
                return `${styles.caller_status_styling} ${styles.idle}`
            case 'working':
                return `${styles.caller_status_styling} ${styles.active}`
            case 'ban':
                return `${styles.caller_status_styling} ${styles.ban}`
            case 'not_looking_to_work':
                return `${styles.caller_status_styling} ${styles.not_looking_to_work}`
            case 'on_a_break':
                return `${styles.caller_status_styling} ${styles.on_a_break}`
            default:
                return `${styles.caller_status_styling} ${styles.not_looking_to_work}`
        }
    }

    const columns = [
        {
            Header: 'Name',
            accessor: "name",
            disableSortBy: true
        },
        {
            Header: 'Email',
            accessor: "email",
            disableSortBy: true
        },
        {
            Header: 'Mobile',
            accessor: "mobile",
            disableSortBy: true
        },
        {
            Header: 'Status',
            accessor: "workStatus",
            Cell: ({value}) => (<div className={styleStatus(value)}>{formatCallerStatus(value)}</div>),
            disableSortBy: true
        },
        {
            Header: 'Projects',
            accessor: "projects",
            Cell: (cell) => {
                return <div className={styles.blue_link} onClick={() => {
                    setTeamSelected({
                        ...teamSelected,
                        agent: {...cell.row.original}
                    })
                    setShowProject(true)
                }}>VIEW</div>
            },
            disableSortBy: true
        },
        {
            id: 'view',
            Header: 'Actions',
            width: "2%",
            Cell: (cell) => {
                return <img src={TrashIcon} onClick={() => {
                    setMarkedAgent([cell.row.original.id])
                    setShowRemoveAgent(true)
                }
                }/>
            },
            disableSortBy: true
        }
 
    ]

    const FallbackUI = () => {
        return(
            <>
                <div className={styles.fallback_wrapper}>
                    <img src={TeammanagementFallback} />
                    <div className={styles.fallback_text_section}>
                        <div className={styles.heading_text}>Get started with your team</div>
                        <div className={styles.description}>
                        <div>Start adding agents to your team and manage projects more effeciently</div>
                        <FutworkButton buttonStyle={'navy--solid'} buttonSize={'sm'}
                        onClick={()=> setShowAddAgent(true)}>Add Agents</FutworkButton>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const {data: agentList, loading, error} = fetchAgentsByTeamLeadApi || {}

    const handlePopups = () => {
        if(showProject) return renderProjectView()
        if(showAddAgent) return renderAddAgentTool()
        if(showRemoveAgent) return renderRemoveAgentTool()
        else return null
    }

    return(
        <div>
            <div className={styles.head_section}>
                <div className={styles.title_back}>
                <img src={BackArrow} className={styles.arrow} onClick={() => {
                    setTeamSelected('')
                    setAgentListView(false)}
                 } />
                 <Heading text = {`${teamSelected && teamSelected.teamName} Agent's`} />
                </div>
                {agentList && agentList.data.length > 0 ? <FutworkButton buttonStyle={'navy--solid'} buttonSize={'sm'} onClick={() => setShowAddAgent(true)}>Add Agents</FutworkButton> : null}
            </div>
            {
                ((agentList && agentList.data.length == 0) && isFilterOff ) ? <FallbackUI/> :
                <Table
                columns={columns}
                data={agentList && agentList.data || []}
                totalCount={agentList && agentList.total}
                limit={limit}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                loading={loading}
                // sortChange={sortChange}
                filterChange={filterChange}
                searchBy='name / mobile / email / status'
                isGlobalFilter={true}
                />
            }
            {handlePopups()}
        </div>
    )
}

export default AgentList