import React, { useState, useEffect } from 'react'
import styles from "../../../assets/stylesheets/pages/teammanagement/teamlead.module.scss"
import TeamList from '../teamlist'
import { OverViewTeamLeadIcon } from '../../../assets/images'

const TeamManagement = (props) => {
    const [navOptions, setnavOptions] = useState([
        'Overview', 
        // 'Dashboard' 
        // dashboard option will be enabled in the next version 
    ])
    const [activeNav, setactiveNav] = useState('Overview')

    return(
        <div className={styles.container}>
            {/* sidebar */}
            <div className={`${styles.sidebar} ${styles.sidebarOpen}`}>
                {
                    navOptions.map((nav, idx) => (
                        <div
                        key={idx}
                        className={`${styles['nav_links']} ${nav === activeNav ? styles['active'] : ''}`}
                        onClick={() => setactiveNav(nav)}
                      >
                        <img src= {OverViewTeamLeadIcon} /> {nav}
                        </div>
                    ))
                }
            </div>

            {/* main content  */}
            <div className={`${styles.content_wrapper} ${styles.default_space}`}>
                <TeamList {...props}/>
            </div>
        </div>
    )
}

export default TeamManagement